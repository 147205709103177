import { Routes } from '@angular/router';
import { canActivate } from './auth/auth.guard';
import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';
import { UserRole } from './types/User';
import { RankingComponent } from './modules/main/ranking/ranking.component';
import { UnauthorizedWarningComponent } from './pages/error/401.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/container-layout/container-layout.routes').then(
        (m) => m.routes,
      ),
  },
  {
    path: 'cms',
    loadChildren: () =>
      import('./modules/cms/cms.routes').then((m) => m.routes),
    data: {
      title: 'CMS',
      rootLink: '/cms',
      roles: [UserRole.SuperAdmin, UserRole.Admin],
    },
    canActivate: [canActivate],
  },
  // {
  //   path: 'admin',
  //   loadChildren: () =>
  //     import('./modules/admin/admin.routes').then((m) => m.routes),
  //   data: {
  //     title: 'CMS',
  //     rootLink: '/admin',
  //     roles: [
  //       EnumRoleUser.SUPER_ADMIN,
  //       EnumRoleUser.ADMIN_SITE,
  //       EnumRoleUser.COMPANY_MANAGER,
  //     ],
  //   },
  //   canActivate: [canActivate],
  // },
  {
    path: 'student',
    loadChildren: () =>
      import('./modules/student/student.routes').then((m) => m.routes),
    data: {
      title: 'Học & Thi',
      rootLink: '/student',
    },
    // canActivate: [canActivate],
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./modules/main/courses/courses.routes').then((m) => m.routes),
  },
  {
    path: 'ranking',
    loadComponent: () =>
      import('./modules/main/ranking/ranking.component').then(
        (m) => RankingComponent,
      ),
  },
  // {
  //   path: 'setting',
  //   loadChildren: () =>
  //     import('./modules/setting/setting.routes').then((m) => m.routes),
  //   data: {
  //     title: 'Cài đặt web',
  //     rootLink: '/setting',

  //     roles: [
  //       EnumRoleUser.SUPER_ADMIN,
  //       EnumRoleUser.ADMIN_SITE,
  //       EnumRoleUser.COMPANY_MANAGER,
  //     ],
  //   },
  //   canActivate: [canActivate],
  // },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'loginOauthGoogle',
    loadComponent: () =>
      import('./pages/loginOauthGoogle/loginOauthGoogle.component').then(
        (m) => m.LoginOauthGoogleComponent,
      ),
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./pages/signup/signup.component').then((m) => m.SignupComponent),
  },

  {
    path: 'learning',
    loadComponent: () =>
      import('./pages/learning/learning.component').then(
        (m) => m.LearningComponent,
      ),
  },
  {
    path: 'do-exam/:id',
    loadComponent: () =>
      import('./pages/do-exam/do-exam.component').then(
        (m) => m.DoExamComponent,
      ),
  },
  {
    path: 'view-exam/:id',
    loadComponent: () =>
      import('./pages/view-exam/view-exam.component').then(
        (m) => m.ViewExamComponent,
      ),
  },
  {
    path: 'show-result/:id',
    loadComponent: () =>
      import('./pages/show-result/show-result.component').then(
        (m) => m.ShowResultComponent,
      ),
  },
  {
    path: 'learning/exam',
    loadComponent: () =>
      import(
        './pages/learning/components/exersice-exam/exersice-exam.component'
      ).then((m) => m.ExersiceExamComponent),
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: '401',
    component: UnauthorizedWarningComponent,
    data: {
      title: 'Unauthorized',
    },
  },
  { path: '**', component: P404Component },
];
